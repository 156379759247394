export default (InputGroupIcon, InputGroupType) =>
  [
    {
      id: 'email',
      tag: 'InputGroup',
      model: 'email',
      rules: ['required', 'email'],
      props: {
        label: 'E-mail',
        type: 'email',
        floatingLabel: true,
        invalidLabel: 'E-mail inválido',
        autoFillColor: 'primary-base',
        theme:'light',
        autoComplete: 'true'
      }
    },
    {
      id: 'password',
      model: 'password',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Senha',
        type: InputGroupType,
        floatingLabel: true,
        invalidLabel: 'Senha inválida',
        autoFillColor: 'primary-base',
        icon: InputGroupIcon,
        theme:'light',
        autoComplete: 'true'
      },
      events: {
        click: 'showPassword'
      }
    },
    {
      id: 'google',
      tag: 'BaseButton',
      props: {
        label: 'Entrar com Google',
        theme: 'icon-left',
        color: 'white',
        iconColor: 'white',
        icon: 'google',
      },
      events: {
        click: 'googleSignIn'
      }
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Entrar',
        theme: 'icon-right',
        color: 'white',
        iconColor: 'white',
        icon: 'chevron-right-alt',
        type: 'submit'
      }
    },
    {
      id: 'forgot',
      tag: 'BaseButton',
      props: {
        label: 'Esqueci minha senha',
        theme: 'simple',
        color: 'white',
      },
      events: {
        click: 'forgotPassword'
      }
    }
  ]