<template>
  <section class="SignInView">
    <BaseLogo class="SignInView__logo" color="white" variation="olistPax" />
    <aside class="SignInView__image" :style="SigninBackground" />
    <DynamicForm
      class="SignInView__form"
      :model="form"
      :blueprint="blueprint(InputGroupIcon, InputGroupType)"
      :endpoint-errors="endpointErrors"
      @submit="onSubmit"
      @forgotPassword="forgotPassword"
      @showPassword="showPassword"
      @googleSignIn="onGoogleSignIn"
    />
  </section>
</template>

<script>
import auth from '@/assets/images/auth/auth.png'
import { mapActions } from 'vuex'
import { BaseLogo } from '@/components/atoms'
import { DynamicForm } from '@/components/molecules'
import blueprint from '@/views/auth/SignIn/SignInForm.blueprint'

export default {
  name:'SignInView',
  components: { BaseLogo, DynamicForm },
  data() {
    return {
      hiddenPassword: true,
      form: {
        email: '',
        password: ''
      },
      endpointErrors: {}
    }
  },
  computed: {
    InputGroupIcon() {
      return this.hiddenPassword ? 'hide' : 'show'
    },
    InputGroupType() {
      return this.hiddenPassword ? 'password' : 'text'
    },
    SigninBackground() {
      return { backgroundImage: 'url(' + auth + ')' }
    }
  },
  methods: {
    blueprint,
    ...mapActions('auth', [
      'signIn',
      'signInGoogle',
      'listenChangesOnUserRole',
      'listenChangesOnRolePermissions'
    ]),

    showPassword(event) {
      if(event) return
      this.hiddenPassword = !this.hiddenPassword
    },

    forgotPassword() {
      this.$router.push('/auth/forgotpassword')
    },

    getUser(){
      return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))  : ''
    },

    async setGtagUser() {
      let email = this.getUser().email
      if (email) {
        await this.$gtag.set({
          'user_id': email,
        })
      }
    },

    async onSubmit(formData) {
      const res = await this.signIn(formData)
      if (res.validation) return this.endpointErrors = res.validation
      if(!res.message) {
        await this.setGtagUser()
        const type = JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
        if (type === 'motorista_dropoff') {
          this.$router.push('/packages/incoming')
        } else {
          this.$router.push('/')
        }
        this.listenChangesOnUserRole(JSON.parse(localStorage.getItem('user')).id)
        this.listenChangesOnRolePermissions(type)
      }
    },

    async onGoogleSignIn() {
      this.$gAuth.signIn().then(async GoogleUser => {
        let authResponse = await GoogleUser.getAuthResponse()
        const res = await this.signInGoogle({
          token: authResponse.id_token
        })
        if (res.validation) return this.endpointErrors = res.validation
        if(!res.message) {
          await this.setGtagUser()
          this.$router.push('/')
        }
      }).catch(error => {
        // eslint-disable-next-line
        console.log('error', error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SignInView {
  background-image: url('../../../assets/images/tracking/bg_login.png');
	background-position: center;
  // @include radialGradient($color-primary-base, $color-primary-stronger);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(min-content, 404px) 1fr;
  grid-template-rows: 2.5rem minmax(200px, 1fr) minmax(200px, 1fr);
  grid-row-gap: 2.5rem;
  grid-template-areas:
    ". logo  ."
    "image image image"
    ". form .";
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  @media (max-width: $viewport-xs) {
    grid-template-rows: 2.5rem minmax(200px, 0.5fr) minmax(200px, 1fr);
    padding: 1rem;
  }
  &__logo {
    grid-area: logo;
  }
  &__image {
    grid-area: image;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &__form {
    grid-area: form;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 40px);
    grid-row-gap: inherit;
    grid-column-gap: 1.25rem;
    grid-template-areas:
      "email email"
      "password password"
      "google confirm"
      "forgot forgot";

    @media (max-width: $viewport-xs) {
      grid-row-gap: 0;
      grid-column-gap: 0;
      grid-template-areas:
      "email email"
      "password password"
      "google google"
      "confirm confirm"
      "forgot forgot";
    }
  }
}
</style>
<style lang="scss">
.SignInView {
  &__form {
    @media (max-width: $viewport-xs) {
      display: flex !important;
      flex-direction: column;
      .InputGroup {
        margin-bottom: 2rem;
      }
    }
  }

  .DynamicForm__forgot {
    margin-top: -1.25rem;
    @media (max-width: $viewport-xs) {
      margin-top: inherit;
    }
  }
}
</style>